import './DeckDetails.scss';

import React, { PureComponent } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import ShareTooltip from './ShareTooltip';
import IconCheckbox from '@/components/common/inputs/IconCheckbox';
import { Deck } from '@/shared/typings';
import ReactMarkdown from 'react-markdown';

export interface Props extends WithNamespaces {
    deck: Deck;
    expansionName: string;
    isUserAuthorized: boolean;
    handleDeckDelete: () => void;
    handleDeckFavoriteToggle: () => void;
    handleDeckWatchlistToggle: () => void;
}

interface State {
    isRemoveDeckModalOpen: boolean;
}

export class DeckDetails extends PureComponent<Props, State> {
    public render() {
        const { deck, expansionName, isUserAuthorized, t } = this.props;
        return (
            <div>
                <div className="deck-details">
                    <div className="deck-details__deck">
                        <h1 className="deck-details__deck-name keyforge-heading-1">
                            {deck.name}
                        </h1>
                        <div className="deck-details__deck-expansion">
                            {t('single-deck.hint.expansion', {
                                defaultValue: 'Expansion: {{expansion}}',
                                expansion: expansionName,
                            })}
                        </div>
                    </div>
                    <div className="deck-details__action-btns">
                        <div className="deck-details__main-actions">
                            <ShareTooltip>
                                <button className="deck-details__action-btn deck-details__share-btn btn-medium">
                                    <span className="btn-content">
                                        {t('single-deck.btn.share', {
                                            defaultValue: 'Share',
                                        })}
                                    </span>
                                </button>
                            </ShareTooltip>
                        </div>
                        {isUserAuthorized && this.renderDeckToggle()}
                    </div>
                </div>
                {deck.flavorText && (
                    <div className='deck-details__flavor-text'>
                        <ReactMarkdown>{deck.flavorText}</ReactMarkdown>
                    </div>
                )}

            </div>
        );
    }

    private renderDeckToggle() {
        return this.props.deck.isMyDeck
            ? this.renderFavoriteToggle()
            : this.renderWatchlistToggle();
    }

    private renderFavoriteToggle = () => {
        const { deck, handleDeckFavoriteToggle, t } = this.props;
        return (
            <IconCheckbox
                variant="favorite"
                checked={deck.favorite}
                onChange={handleDeckFavoriteToggle}
                label={
                    deck.favorite
                        ? t('single-deck.btn.remove-from-favorite', {
                              defaultValue: 'Remove Deck From Favorites',
                          })
                        : t('single-deck.btn.add-to-favorite', {
                              defaultValue: 'Add Deck To Favorites',
                          })
                }
                className="deck-details__toggle-btn"
            />
        );
    };

    private renderWatchlistToggle = () => {
        const { deck, handleDeckWatchlistToggle, t } = this.props;
        return (
            <IconCheckbox
                variant="watchlist"
                checked={deck.isOnWatchlist}
                onChange={handleDeckWatchlistToggle}
                label={
                    deck.isOnWatchlist
                        ? t('general.hint.remove-from-watchlist', {
                              defaultValue: 'Remove from My Watchlist',
                          })
                        : t('general.hint.add-to-watchlist', {
                              defaultValue: 'Add to My Watchlist',
                          })
                }
                className="deck-details__toggle-btn"
            />
        );
    };
}

export default withNamespaces()(DeckDetails);
