import React, { useEffect } from 'react';
import { sha1Hash } from '@/utils/crypto';

interface Feature<T = boolean> {
    getValue?: () => T | Promise<T>;
    defaultValue: T;
}

type Features = typeof features;
type FeatureValue<K extends keyof Features> = Awaited<
    ReturnType<Features[K]['getValue']>
>;

type FeaturesState = {
    [K in keyof Features]: FeatureValue<K>;
};

// When features need to be enabled for everybody, set defaultValue to true
// and either remove the flag completely or remove the `getValue` function.
const features = {
    newDeckOwnership: {
        async getValue(): Promise<boolean> {
            const val = await getLocalStorageVal('ownership_feature');
            return val === null ? this.defaultValue : val;
        },
        defaultValue: true,
    },
    alliances: {
        async getValue() {
            const val = await getLocalStorageVal('alliances_feature');
            return val === null ? this.defaultValue : val;
        },
        defaultValue: true
    },
    friends: {
        async getValue() {
            const val = await getLocalStorageVal('friends_feature');
            return val === null ? this.defaultValue : val;
        },
        defaultValue: true
    }
// todo update prettier, migrate tslint to eslint
// tslint:disable-next-line:prettier
} satisfies Record<string, Feature>;

async function getLocalStorageVal (key: string) {
    if(!('localStorage' in window)) {
        return null;
    }
    try {
        const keyHash = await sha1Hash(key);
        const val = window.localStorage.getItem(keyHash);
        return JSON.parse(val);
    } catch (e) {
        return null;
    }
}

export const getInitialFlags = (features: Features): FeaturesState =>
    Object.entries(features).reduce(
        (acc: FeaturesState, [key, feature]) => ({
            ...acc,
            [key]: feature.defaultValue,
        }),
        {} as any
    );

const FlagsContext = React.createContext(getInitialFlags(features));

export const useFlag = <K extends keyof FeaturesState>(
    feature: K
): FeatureValue<K> => React.useContext(FlagsContext)[feature];

export const Flags: React.FC = (props) => {
    const [flags, setFlags] = React.useState<FeaturesState>({} as any);

    useEffect(() => {
        Object.entries(features).forEach(async ([key, feature]) => {
            const value = feature.getValue
                ? await feature.getValue()
                : feature.defaultValue;
            setFlags((flags) => ({
                ...flags,
                [key]: value,
            }));
        });
    }, []);

    return (
        <FlagsContext.Provider value={flags}>
            {props.children}
        </FlagsContext.Provider>
    );
};
